import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useTranslation } from 'react-i18next';
import { Icon } from '@mui/material';
import { Language } from '@mui/icons-material';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import nav from 'src/nav';

const languagesList = [
  { code: "en", label: "English" },
  { code: "ar", label: "العربية" },
  { code: "fr", label: "Français" },
];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [navTo, setNavTo] = React.useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    if (navTo) {
      setTimeout(()=>{navigate(navTo)}, 600)
    }
  }, [anchorElNav])

  return (
    <AppBar position="sticky" sx={{
      backgroundColor: "secondary.main",
      boxShadow: "none"
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Icon sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: "column", gap: "1px", fontSize: "65px" }}>
            <img src="/images/cfaLogoOnly.svg" style={{ width: "65%", margin: "0 auto", paddingTop: "3px" }} />
            <Typography
              component="span"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "0.1em",
                color: 'primary.main',
                lineHeight:"7px"
              }}
            >CYCTIC FIBROSIS ASSOSIATION</Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "0.1em",
                color: '#0B0B0B9E',
                lineHeight:"6px"
              }}
            >FOR LEBANON</Typography>
          </Icon>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {nav.map((page, idx) => (
                <MenuItem key={idx} onClick={() => {
                  handleCloseNavMenu()
                  setNavTo(page.to)
                }}>
                  {t(page.name)}
                  {/* <Link
                      key={idx}
                      sx={{ my: 2, display: 'block', mx: 1,textDecoration:"none" }}
                      component="button"
                      variant="body2"
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(page.to)
                      }}
                    >
                      {t(page.name)}
                    </Link> */}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, fontSize: "45px" }}>
            <img src="/images/cfaLogoOnly.svg" style={{ width: "100%" }} />
          </Icon>
          <Typography
            variant="h5"
            noWrap
            component="span"
            href="#app-bar-with-responsive-menu"
            noWrap
            sx={{
              fontFamily: "Montserrat",
              fontSize: "1em",
              color: 'primary.main',
              mr: 2,
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              flexDirection: "column",
              gap: "1px"
            }}
          >
            <Typography noWrap component="span" sx={{ lineHeight:"9px",color: 'primary.main', fontFamily: "Montserrat", fontSize: "0.1em", }}>CYCTIC FIBROSIS</Typography>
            <Typography noWrap component="span" sx={{ lineHeight:"9px",color: 'primary.main', fontFamily: "Montserrat", fontSize: "0.1em", }}>ASSOSIATION</Typography>
            <Typography noWrap component="span" sx={{ lineHeight:"9px",color: '#0B0B0B9E', fontFamily: "Montserrat", fontSize: "7px", }}>FOR LEBANON</Typography>
          </Typography>
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {nav.map((page, idx) => (
              <Link
                key={idx}
                sx={{ my: 2,px:1, fontSize: "1.3em", color: 'primary', textDecoration: "none", display: 'block', mx: 1 }}
                component="button"
                variant="body2"
                onClick={() => {
                  navigate(page.to)
                }}
              >
                {t(page.name)}
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Select Language">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} sx={{ color: "primary.main" }}>
                <Language sx={{ mx: 1 }} />
                {i18n.language}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {languagesList.map((language) => (
                <MenuItem key={language.code} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center" onClick={() => {
                    i18n.changeLanguage(language.code)
                  }}>{language.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;