let _nav = [
  {
    name: 'home',
    to: '/',
  },
  {
    name: 'introToCFA',
    to: '/intro',
  },
  {
    name: 'news',
    to: '/news',
  },
  {
    name: 'enroll',
    to: '/enroll',
  },
  {
    name: 'support',
    to: '/support',
  },
  {
    name: 'donate',
    to: '/donate',
  },
]
export default _nav