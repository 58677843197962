import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const Intro = React.lazy(() => import("./pages/Intro"));
const News = React.lazy(() => import("./pages/news"));
const newsPage = React.lazy(() => import("./pages/news/NewsPage"));
const Enroll = React.lazy(() => import("./pages/enroll"));
const Support = React.lazy(() => import("./pages/support"));
const ConfirmEmail = React.lazy(() => import("./pages/enroll/ConfirmEmail"));
const VerifyEmail = React.lazy(() => import("./pages/enroll/VerifyEmail"));
const Donate = React.lazy(() => import("./pages/donate"));

const routes = [
  { path: "/", exact: true, component: Home },
  { path: "/intro", exact: true, component: Intro },
  { path: "/news", exact: true, component: News },
  { path: "/news/:id", exact: true, component: newsPage },
  { path: "/enroll", exact: true, component: Enroll },
  { path: "/enroll/ConfirmEmail", exact: true, component: ConfirmEmail },
  { path: "/enroll/verifyEmail/:verifyEmailToken", exact: true, component: VerifyEmail },
  { path: "/support", exact: true, component: Support },
  { path: "/donate", exact: true, component: Donate },
  { path: "*", exact: true, component: Home },

];

export default routes;
