import React, { Suspense } from "react";
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  HashRouter,
  Routes as ReactRoutes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Layout from "./components/layout";
import routes from "./routes";
import "./i18n";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';


// Create ltr cache
const cacheLtr = createCache({
  key: 'muiltr',
});
// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});



const hist = createBrowserHistory();
const ltrTheme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: "#8041A8",
      dark: "#180126",
    },
    secondary: {
      main: "#e3ebee",
    },
  },
});
const rtlTheme = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: "#8041A8",
      dark: "#180126",
    },
    secondary: {
      main: "#e3ebee",
    },
  },
});

const Loader = () => (
  <p>LOADING</p>
);

function App() {
  const { t, i18n } = useTranslation();

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", i18n.language == "ar" ? "rtl" : "ltr");
  }, [i18n.language]);

  return (
    <CacheProvider value={i18n.language == "ar" ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={i18n.language == "ar" ? rtlTheme : ltrTheme}>
        <BrowserRouter history={hist}>
          <Suspense fallback={<Loader />}>
            <ToastContainer />
            <Layout>
              <ReactRoutes>
                {routes.map((route, idx) =>
                  <Route key={idx}
                    path={route.path}
                    exact={route.exact}
                    element={<route.component />}>
                    {route.children?.map((x, idx2) => (
                      <Route key={idx2}
                        path={x.path}
                        exact={x.exact}
                        element={<p>vregregr</p>} />
                    ))}
                  </Route>
                )}
              </ReactRoutes>
            </Layout>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
